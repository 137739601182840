import React from "react";

import Box from "components/Box";
import Meta from "components/Meta";

const NotFoundPage = () => (
  <Box variant="container">
    <Box mx={4}>
      <Meta title="404: Not found" />
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn't</p>
    </Box>
  </Box>
);

export default NotFoundPage;
